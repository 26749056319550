<template>
  <div>
    <v-row class="text-center mb-4">
      <v-col cols="12" class="text-center">
        <h1 style="color: white; font-family: 'Raleway light', sans-serif">
          {{ $t("service_page.title") }}
        </h1>
      </v-col>
      <v-col cols="12" class="text-center mb-6">
        <span style="color: white; font-size: 20px">
          {{ $t("service_page.description") }}
        </span>
      </v-col>
      <v-col md="12">
        <v-row>
          <v-col
            md="4"
            class="px-5"
            v-for="(service, index) of services"
            :key="index"
          >
            <v-card width="100%" height="100%" class="border-16 pa-8 text-left">
              <div>
                <p
                  style="
                    width: 48px;
                    background-color: #ff5096 !important;
                    height: 4px !important;
                  "
                  class="border-16"
                ></p>
                <h3
                  style="
                    font-family: 'Raleway light', sans-serif;
                    font-size: 20px;
                  "
                >
                  {{ $t(service.title) }}
                </h3>
                <div
                  style="
                    font-size: 20px;
                    font-family: 'Raleway medium', sans-serif;
                  "
                  class="mt-4"
                >
                  {{ $t(service.description) }}
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <h1 class="color-white mt-2">
          {{ $t("service_page.whoisit_for_question_text") }}
        </h1>
        <p style="font-size: 20px" class="color-white">
          {{ $t("service_page.whoisit_for_answer_text") }}
        </p>
      </v-col>
    </v-row>
    <Footer color="#fff"/>
  </div>
</template>

<script>
export default {
  name: "Services",
  components: {
    Footer: () => import("@/components/Footer")
  },
  data() {
    return {
      services: [
        {
          title: "service_page.forsme_owners_card_title",
          description: "service_page.forsme_owners_card_description",
        },
        {
          title: "service_page.fordigital_marketers_card_title",
          description: "service_page.fordigital_marketers_card_description",
        },
        {
          title: "service_page.business_owners_card_title",
          description: "service_page.business_owners_card_description",
        },
      ],
    };
  },
};
</script>

<style scoped></style>
